<template>
  <div>
    <div v-if="currentPage === ''">
      <h2 class="title my-3">Groups</h2>
      <hr />
      <div v-if="usertype === 'admin'" class="form-row">
      <div class="form-group col-12">
        <v-select
          v-model="orgSelector"
          :options="orgs"
          :create-option="(org) => ({ id: '', name: 'Select an org' })"
          :reduce="(name) => name.id"
          label="name"
        >
          <template #header>
            <div style="opacity: 0.8">Select an organisation</div>
          </template>
        </v-select>
      </div>
    </div>
      <div v-if="loaded">
        <div class="row mb-3">       
        <div class="col-md-4">
          
          <div class="border rounded text-center text-md-left mb-3 mb-md-0 p-2">
            <p
              :class="{ 'text-danger': numberUsersNoGroup > 0 }"
              class="py-1 mb-0"
            >
              <span class="h3">{{ numberUsersNoGroup }}</span>
              Users without a group
              <span
                v-if="numberUsersNoGroup > 0"
                @click="openListModal('users')"
                class="custom-link text-primary"
              >
                <i class="bi bi-info-circle"></i>
              </span>
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="border rounded text-center text-md-left mb-3 mb-md-0 p-2">
            <p
              :class="{ 'text-danger': numberWorkplacesNoGroup > 0 }"
              class="py-1 mb-0"
            >
              <span class="h3">{{ numberWorkplacesNoGroup }}</span>
              Projects without a group
              <span
                v-if="numberWorkplacesNoGroup > 0"
                @click="openListModal('projects')"
                class="custom-link text-primary"
              >
                <i class="bi bi-info-circle"></i>
              </span>
            </p>
          </div>
        </div>
      </div>
      <b-row class="mt-3">
        <b-col cols="12" class="text-right mb-3">
          <b-button @click="openAddGroupModal" variant="primary" v-if="usertype === 'orgAdmin' && canManageGroups" >
            + Add group
          </b-button>
        </b-col>
        <b-col cols="12">
          <b-table
            :items="groups"
            :fields="fields"
            :busy="isBusy"
            stacked="md"
            striped
            bordered
            show-empty
          >
            <!-- Group name -->

            <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle mr-2" />
                  <strong>Loading groups...</strong>
                </div>
              </template>            
            <template #cell(name)="row">
              <div class="d-flex align-items-center justify-content-between">
                <span>{{ row.item.name }}</span>
                <b-button
                  @click="openEditModal(row.item.groupID, row.item.name, row.item.type)"
                  variant="link"
                  v-if="canManageGroups || (permissionsByGroup[row.item.groupID] && permissionsByGroup[row.item.groupID].EDIT_GROUP_DETAILS)"
                >
                  <i class="bi bi-pencil-square"></i>
                </b-button>
              </div>
            </template>
            <!-- Group type -->
            <template #cell(type)="row">
              <div class="d-flex align-items-center justify-content-between">
                <span>{{ row.item.type }}</span>
                <b-button
                  @click="openEditModal(row.item.groupID, row.item.name, row.item.type)"
                  variant="link"
                  v-if="canManageGroups || (permissionsByGroup[row.item.groupID] && permissionsByGroup[row.item.groupID].EDIT_GROUP_DETAILS)"
                >
                  <i class="bi bi-pencil-square"></i>
                </b-button>
              </div>
            </template>
            <template #cell(userList)="row">
              {{ row.item.userList ? row.item.userList.length : 0 }} users
              <b-button
                @click="changeCurrentPage('users', row.item)"
                v-if="canManageGroups"
                variant="link"
                class="p-0 ml-2"
              >
                <i class="bi bi-pencil-square text-primary"></i>
              </b-button>
            </template>
            <template #cell(workplaceList)="row">
              {{ row.item.workplaceList ? row.item.workplaceList.length : 0 }}
              projects
              <b-button
                @click="changeCurrentPage('workplaces', row.item)"
                v-if="canManageGroups"
                variant="link"
                class="p-0 ml-2"
              >
                <i class="bi bi-pencil-square text-primary"></i>
              </b-button>
            </template>
            <template #cell(delete)="row">
              <b-button @click="deleteGroup(row.item.groupID)" variant="dark">
                <i class="bi bi-trash-fill"></i>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>
  </div>
    <div v-else>
      <b-row class="mb-3">
        <b-col>
          <b-button @click="goBackGroupsSection" class="mr-1 mt-1" variant="light">
            All Groups
          </b-button>
        </b-col>
      </b-row>   
      <h2 class="title my-3">Group: {{ groupSelected.name }}</h2>
      <b-row class="mb-3">
        <b-col>
          <b-button
            class="mr-1"
            @click="changeCurrentPage('users')"
            variant="light"
          >
            Group Members
          </b-button>
          <b-button @click="changeCurrentPage('workplaces')" variant="light">
            Projects
          </b-button>
        </b-col>
      </b-row>
      <template v-if="currentPage === 'users'">
        <AddUsersToGroup
          :groupID="groupSelected.groupID"
          :organisationID="orgIdToSend"
          :token="token"
          :permissions="permissionsByGroup[groupSelected.groupID]"
        />
      </template>
      <template v-if="currentPage === 'workplaces'">
        <AddWorkplacesToGroup
          :groupID="groupSelected.groupID"
          :organisationID="orgIdToSend"
          :token="token"
          :permissions="permissionsByGroup[groupSelected.groupID]"
        />
      </template>
    </div>
    <stack-modal
      :show="showEditModal"
      @save="editGroup"
      @close="showEditModal = false"
      title="Edit group"
    >
      <div class="form-group">
        <label for="editGroupName">Group name</label>
        <input
          id="editGroupName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': formEditData.nameError.length > 0 }"
          v-model="formEditData.name"
        />
        <div class="invalid-feedback">{{ formEditData.nameError }}</div>
      </div>
      <div class="form-group">
        <label for="editGroupDescription">Group description</label>
        <input
          id="editGroupDescription"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': formEditData.descriptionError.length > 0 }"
          v-model="formEditData.description"
        />
        <div class="invalid-feedback">{{ formEditData.descriptionError }}</div>
      </div>
    </stack-modal>
    <stack-modal
      :show="showListModal"
      @close="showListModal = false"
      :title="dataListModal.titleModal"
    >
      <ul v-if="dataListModal.arrToShow">
        <li v-for="(item, index) in dataListModal.arrToShow" :key="index">
          <span v-if="item.email">{{ item.email }}</span>
          <span v-else>{{ item.name }}</span>
        </li>
      </ul>
      <div slot="modal-footer"></div>
    </stack-modal>
    <stack-modal
      :show="showAddGroupModal"
      @save="addGroup"
      @close="showAddGroupModal = false"
      title="Add new group"
      :saveButton="{ title: 'Add group' }"
    >
      <div class="form-group">
        <label for="newGroupName">Enter group name</label>
        <input
          id="newGroupName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': newGroupNameError.length > 0 }"
          v-model="newGroupName"
        />
        <div class="invalid-feedback">{{ newGroupNameError }}</div>
      </div>
      <div class="form-group">
        <label for="newGroupDescription">Enter group description</label>
        <input
          id="newGroupDescription"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': newGroupDescriptionError.length > 0 }"
          v-model="newGroupDescription"
        />
        <div class="invalid-feedback">{{ newGroupDescriptionError }}</div>
      </div>
    </stack-modal>
  </div>
</template>

<script>
import axios from "axios";
import StackModal from "@innologica/vue-stackable-modal";
import { EventBus } from "../../main";
export default {
  name: "GroupsManager",
  components: {
    AddUsersToGroup: () => import("../Components/AddUsersToGroup"),
    AddWorkplacesToGroup: () => import("../Components/AddWorkplacesToGroup"),
    StackModal,
  },
  props: {
    organisationID: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    userID: {
      type: String,
      required: true,
    },
    usertype: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      baseURL: `${this.$address}api`,
      isBusy:false,
      currentPage: "",
      loaded: true,
      errorMsg: "This name is not avaible.",
      fields: [
        { key: "name", label: "Group name", sortable: true },
        { key: "type", label: "Group description", sortable: true },
        { key: "userList", label: "Users", sortable: true },
        { key: "workplaceList", label: "Projects", sortable: true },
      ],
      formGroup: {
        name: "",
        type: "",
        organisationID: this.organisationID,
      },
      groups: [],
      permissionsByGroup: {},
      groupSelected: {
        groupID: "",
        name: "",
      },
      showEditModal: false,
      showListModal: false,
      dataListModal: {
        titleModal: "",
        users: [],
        projects: [],
        arrToShow: [],
      },
      formEditData: {
        groupID: "",
        name: "",
        description: "",
        nameError: "",
        descriptionError: "",
      },
      validName: true,
      validType: true,
      orgSelector: "",
      orgs:[],
      showAddGroupModal: false,
      newGroupName: "",
      newGroupDescription: "",
      newGroupNameError: "",
      newGroupDescriptionError: "",
    };
  },
  computed: {
    orgIdToSend() {
      return this.usertype === "admin" && this.orgSelector
        ? this.orgSelector
        : this.organisationID;
    },    
    numberUsersNoGroup() {
      return this.dataListModal.users.length;
    },
    numberWorkplacesNoGroup() {
      return this.dataListModal.projects.length;
    },
    canManageGroups() {
      return this.$accessControlStore.permissions.MANAGE_GROUPS;
    },
    customRolesEnabled(){
      return this.$accessControlStore.customRoles;
    }
  },
  watch: {
    orgSelector(newValue, oldValue) {
      this.loaded = true;
    this.organisationID = newValue
    this.getGroups();
    this.getUsersWorkplacesWithoutGroup();
    this.getOrganisations();
    },
  },  
  created() {
    if (this.usertype === "admin") {
      this.loaded = false;
    }
    EventBus.$on("changeGroupsTab", () => {
      this.validName = true;
      this.validType = true;
      this.formGroup.name = "";
      this.formGroup.type = "";
      this.groups = [];
      this.currentPage = "";
      this.groupSelected.groupID = "";
      this.groupSelected.name = "";
      this.getGroups();
      this.getUsersWorkplacesWithoutGroup();
    });
    this.getGroups();
    this.getUsersWorkplacesWithoutGroup();
    this.getOrganisations();
  },
  mounted() {
    if (this.canManageGroups) {
      this.fields.push({ key: "delete", label: "Delete" });
    }
  },
  methods: {
    async getGroups() {
      this.isBusy = true;
      if (this.usertype === "manager") {
        const userInfo = await axios.get(
          `${this.baseURL}/UserAccounts/${this.userID}?access_token=${this.token}`
        );
        for (const groupID of userInfo.data.groupList) {
          const groupInfo = await axios.get(
            `${this.baseURL}/Groups/${groupID}?access_token=${this.token}`
          );
          
          this.groups.push(groupInfo.data);
        }
        this.isBusy = false;
      } else {
        const groupList = await axios.get(
          `${this.baseURL}/Groups?access_token=${this.token}`,
          {
            params: {
              filter: {
                where: { organisationID: this.organisationID },
              },
            },
          }
        );
        this.isBusy = false;
        this.groups = groupList.data;
      }
      
      if (this.customRolesEnabled) await this.fetchPermissionsByGroup();
    },
    async fetchPermissionsByGroup(){
      for (const group of this.groups) {
        const userGroupRole = await axios.get(
          `${this.baseURL}/UserGroupRoles/getSpecificUserGroupRole/?groupId=${group.groupID}&userId=${this.userID}`
        );
        //Temporary fix while a proper validation is set for when the user does not have role in the group
        if (!userGroupRole.data.data){
          let permissions;
          const defaultPermissions = {
            "EDIT_GROUP_DETAILS": false,
            "EDIT_GROUP_USERS": false,
            "EDIT_GROUP_PROJECTS": false,
            "CREATE_INSTRUCTION": false,
            "DELETE_INSTRUCTION": false,
            "EDIT_INSTRUCTION": false,
            "PROTECT_INSTRUCTION": false,
            "APPROVE_TECHNICALLY": false,
            "APPROVE_DATA_SECURITY": false,
            "MANAGE_WEB_LINK": false
          };
          this.$set(this.permissionsByGroup, group.groupID, defaultPermissions);
        }
        const permissionSet = await axios.get(
          `${this.baseURL}/groupRoles/getGroupRolesPermissions?access_token=${this.token}`,
          {
            params: {
              groupRoleId: userGroupRole.data.data.groupRoleId,
            },
          }
        );

        this.$set(this.permissionsByGroup, group.groupID, permissionSet.data.permissions);
      }
    },
    async getUsersWorkplacesWithoutGroup() {
      const { data } = await axios.get(
        `${this.baseURL}/Organisations/getOrganisationUsersWorkplacesWithoutGroup?access_token=${this.token}`,
        {
          params: { organisationID: this.organisationID },
        }
      );
      if (!data) return;
      this.dataListModal.users = data["users"];
      this.dataListModal.projects = data["workplaces"];
    },
    async createGroup() {
      this.formGroup.name = this.formGroup.name.trim();
      this.formGroup.type = this.formGroup.type.trim();
      this.validName = true;
      this.validType = true;
      if (this.formGroup.name.length === 0) {
        this.errorMsg = "A group name is required.";
        this.validName = false;
      }
      if (this.formGroup.name === "default") {
        this.errorMsg = "This name is not avaible.";
        this.validName = false;
      }
      if (this.formGroup.type.length === 0) {
        this.validType = false;
      }
      if (!this.validName || !this.validType) return;
      const { data } = await axios.post(
        `${this.baseURL}/Groups?access_token=${this.token}`,
        this.formGroup
      );
      this.groups.push(data);
      this.formGroup.name = "";
      this.formGroup.type = "";
    },
    async deleteGroup(groupID = "") {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this group",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "Abort",
          }        
        )
        .then(async (confirmDelete) => {
          if (!confirmDelete) return;
          await axios.delete(
            `${this.baseURL}/Groups/${groupID}?access_token=${this.token}`
          );
          const idx = this.groups.findIndex((item) => item.groupID === groupID);
          this.groups.splice(idx, 1);
          this.getUsersWorkplacesWithoutGroup();
        });
    },
    async editGroup() {
      this.formEditData.nameError = "";
      this.formEditData.descriptionError = "";

      if (this.formEditData.name.trim().length === 0) {
        this.formEditData.nameError = "A group name is required.";
      }
      if (this.formEditData.name.trim() === "default") {
        this.formEditData.nameError = "This name is not available.";
      }
      if (this.formEditData.description.trim().length === 0) {
        this.formEditData.descriptionError = "A group description is required.";
      }

      if (this.formEditData.nameError || this.formEditData.descriptionError) return;

      try {
        await axios.patch(
          `${this.baseURL}/Groups/${this.formEditData.groupID}?access_token=${this.token}`,
          {
            name: this.formEditData.name.trim(),
            type: this.formEditData.description.trim(),
          }
        );
        const idx = this.groups.findIndex(
          (item) => item.groupID === this.formEditData.groupID
        );
        this.groups[idx].name = this.formEditData.name.trim();
        this.groups[idx].type = this.formEditData.description.trim();
        this.showEditModal = false;
      } catch (error) {
        this.formEditData.nameError = "No permission to edit Group";
        this.formEditData.descriptionError = "No permission to edit Group";
        console.error("Error editing group:", error);
      }
    },
    openEditModal(groupID, name, description) {
      this.formEditData = {
        groupID,
        name,
        description,
        nameError: "",
        descriptionError: "",
      };
      this.showEditModal = true;
    },
    openListModal(field = "") {
      if (field !== "users" && field !== "projects") return;
      if (field === "users") {
        this.dataListModal.titleModal = "Users without a group";
      } else {
        this.dataListModal.titleModal = "Projects without a group";
      }
      this.dataListModal.arrToShow = this.dataListModal[field];
      this.showListModal = true;
    },
    goBackGroupsSection() {
      EventBus.$emit("changeGroupsTab");
    },
    changeCurrentPage(newPage = "", group) {
      this.currentPage = newPage;
      if (group) {
        this.groupSelected.groupID = group.groupID;
        this.groupSelected.name = group.name;
      }
    },
    async getOrganisations() {
      try {
        const { data } = await axios.get(
          `${this.baseURL}/Organisations?access_token=${this.token}`,
          {
            params: {
              filter: { fields: { id: true, name: true }, order: "name ASC" },
            },
          }
        );
        this.orgs = data;
      } catch (error) {
        console.error(error);
      }
    },    
    openAddGroupModal() {
      this.showAddGroupModal = true;
      this.newGroupName = "";
      this.newGroupDescription = "";
      this.newGroupNameError = "";
      this.newGroupDescriptionError = "";
    },

    async addGroup() {
      this.newGroupNameError = "";
      this.newGroupDescriptionError = "";

      if (this.newGroupName.trim().length === 0) {
        this.newGroupNameError = "A group name is required.";
      }
      if (this.newGroupName.trim() === "default") {
        this.newGroupNameError = "This name is not available.";
      }
      if (this.newGroupDescription.trim().length === 0) {
        this.newGroupDescriptionError = "A group description is required.";
      }

      if (this.newGroupNameError || this.newGroupDescriptionError) return;

      try {
        const { data } = await axios.post(
          `${this.baseURL}/Groups?access_token=${this.token}`,
          {
            name: this.newGroupName.trim(),
            type: this.newGroupDescription.trim(),
            organisationID: this.organisationID,
          }
        );
        this.groups.push(data);
        this.showAddGroupModal = false;
      } catch (error) {
        console.error("Error adding group:", error);
      }
    },
  },
};
</script>

<style scoped>
h2.title {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-bottom: 50px;
}
.custom-link:hover {
  cursor: pointer;
  cursor: hand;
  text-decoration: underline;
}
::v-deep .table > tbody > tr > td {
vertical-align: middle !important;
}
::v-deep .table thead th {
vertical-align: middle !important;
}
</style>